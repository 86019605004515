import React from 'react';
import { PatternVertical } from '../../pattern';
import Wrapper from '../wrapper';
import './hero.scss';

function Hero() {
  return (
    <section id='hero'>
      <Wrapper center width='1440'>
        <h1 className='display'>
          Vi er spesialister på markedsføring og digital forretningsutvikling
        </h1>
      </Wrapper>
      <PatternVertical />
    </section>
  );
}

export default Hero;
