import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
/* import { ButtonLink } from '../layout/buttons'; */
import Wrapper from '../layout/wrapper';
import './cases.scss';

export const Case = ({ client, /* content, */ logo, /* url, */ alt, id }) => (
  <article className='single-case' key={id}>
    {logo ? (
      <Img className='client-logo' fluid={logo} alt={alt} objectFit='contain' />
    ) : (
      client
    )}
    {/* <h3 className='case-title'>{client}</h3> */}
    {/* <div
      className='case-excerpt'
      dangerouslySetInnerHTML={{ __html: content }}
    ></div> */}
    {/* <ButtonLink type='primary' to={url ? url : '/'} text='Les mer' /> */}
  </article>
);

export function SelectedCases() {
  const caseData = useStaticQuery(graphql`
    query selectedCases {
      allWpPost(sort: { fields: title, order: ASC }) {
        edges {
          node {
            id
            title
            content: caseInnhold {
              utdrag
              content
              info: omKunde {
                logo {
                  description
                  lf: localFile {
                    cis: childImageSharp {
                      fluid(
                        toFormat: PNG
                        maxHeight: 56
                        fit: INSIDE
                        background: "transparent"
                        pngCompressionSpeed: 10
                        pngQuality: 10
                      ) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section className='selected-cases'>
      <Wrapper center>
        <h2>Kunder vi har hjulpet å vokse</h2>
        <div className='case-grid'>
          {caseData.allWpPost.edges.map((item) => (
            <Case
              key={item.node.id}
              client={item.node.title}
              alt={item.node.title}
              content={item.node.content.utdrag}
              logo={item.node.content.info.logo.lf.cis.fluid}
            />
          ))}
        </div>
      </Wrapper>
    </section>
  );
}
