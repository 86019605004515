import React from 'react';

import Layout from '../components/layout/layout';
import Hero from '../components/layout/hero';
import { Link } from 'gatsby';
import Wrapper from '../components/layout/wrapper';
import { Flex, FlexChild } from '../components/layout/flex';
import { SelectedCases } from '../components/cases';
/* import ArticleGrid from '../components/articles/grid'; */

import Detail1 from '../images/environment/detail-1.jpg';
import Detail2 from '../images/environment/workshop-5.jpg';
import SEO from '../components/seo'

function IndexPage() {
  return (
    <Layout>
      <SEO title='Caulis – Digitale konsulenter – Norges fremste konsulenter' />
      <Hero />
      <Wrapper center>
        <section id='about'>
          <Flex className='margin-m--t' margin='5'>
            <FlexChild centerContent>
              <div>
                <h2>Konsulent- og mediebyrå</h2>
                <p className='larger'>
                 I Caulis er vi opptatt av helheten. Vi jobber med å skape vekst gjennom markedsføring, annonseteknologi og personvern, og tilbyr mediebyråtjenester og konsulenttjenester innenfor dette.
                </p>
                <Link to='/tjenester'>Se tjenestene våre</Link>
              </div> 
            </FlexChild>
            <FlexChild className='image-container' centerContent>
              <img name='detail-photo' src={Detail2} alt='' />
            </FlexChild>
          </Flex>
          <Flex className='flex-switch-s' margin='5'>
            <FlexChild className='image-container' centerContent>
              <img name='detail-photo' src={Detail1} alt='' />
            </FlexChild>
            <FlexChild width='50%' centerContent className='text-container'>
              <div>
                <h2>Team Caulis</h2>
                <p className='larger'>
                  Teamet vårt er satt sammen av medierådgivere, spesialister og konsulenter med ulik bakgrunn og ulik fagkompetanse. Dette gjør at vi kan identifisere, initiere og levere de tiltakene som passer best for dine kunder der de befinner seg i kundereisen.
                </p>
                <Link to='/kontakt'>Kontakt oss</Link>
              </div>
            </FlexChild>
          </Flex>
        </section>
      </Wrapper>
      <SelectedCases />
      {/* <ArticleGrid /> */}
    </Layout>
  );
}

export default IndexPage;
