import React from 'react';
import PropTypes from 'prop-types';
import './flex.scss';

export const Flex = ({
  children,
  height,
  fullHeight,
  width,
  className,
  margin,
  props,
}) => (
  <div
    className={`flex-container ${className || ''}`}
    style={{
      height: height ? height : 'auto',
      width: width ? width : 'auto',
    }}
    {...props}
    full={fullHeight ? 'true' : 'false'}
    margin={margin ? margin : null}
  >
    {children}
  </div>
);

export const FlexChild = ({
  children,
  order,
  width,
  className,
  props,
  centerContent,
}) => (
  <div
    className={`flex-child ${className || ''}`}
    style={{
      order: order ? order : undefined,
      width: width ? width : '1fr',
    }}
    {...props}
    center-content={centerContent ? 'true' : 'false'}
  >
    {children}
  </div>
);

FlexChild.propTypes = {
  order: Number,
  width: Number,
  margin: Number,
  centerContent: Boolean,
};

FlexChild.defaultProps = {
  order: 0,
  width: 'auto',
};

Flex.propTypes = {
  children: PropTypes.element.isRequired,
  fullHeight: Boolean,
};

Flex.defaultProps = {
  width: '100vw',
  fullHeight: false,
};
