import React from 'react';
import './pattern.scss';

export const PatternVertical = () => (
  <svg
    width='530px'
    height='846px'
    viewBox='0 0 530 846'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    id='brand-pattern'
    name='frontpage'
  >
    <g>
      <polyline
        id='route'
        stroke='#697364'
        strokeWidth='2'
        points='501 29 186.5 186.5 29 344.5 343.5 502 186.5 660 501 817'
      ></polyline>
      <circle
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='29'
        cy='817'
        r='28'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='29'
        cy='660'
        rx='27'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='29'
        cy='502'
        rx='27'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob filled'
        fillorder='four'
        stroke='#3B5448'
        fill='#3B5448'
        cx='29'
        cy='344.5'
        rx='27.5'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='29'
        cy='186.5'
        rx='27.5'
        ry='28'
      ></ellipse>
      <circle
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='29'
        cy='29'
        r='28'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='186.5'
        cy='817'
        rx='28'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob filled'
        fillorder='second'
        stroke='#3B5448'
        fill='#3B5448'
        cx='186.5'
        cy='660'
        rx='27'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='186.5'
        cy='502'
        rx='27'
        ry='27.5'
      ></ellipse>
      <circle
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='186.5'
        cy='344.5'
        r='27.5'
      ></circle>
      <circle
        className='blob filled'
        fillorder='fifth'
        stroke='#3B5448'
        fill='#3B5448'
        cx='186.5'
        cy='186.5'
        r='27.5'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='186.5'
        cy='29'
        rx='28'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='343.5'
        cy='817'
        rx='28'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='343.5'
        cy='660'
        rx='27'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob filled orange'
        fillorder='third'
        stroke='#EF7856'
        fill='#EF7856'
        cx='343.5'
        cy='502'
        rx='27'
        ry='27.5'
      ></ellipse>
      <circle
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='343.5'
        cy='344.5'
        r='27.5'
      ></circle>
      <circle
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='343.5'
        cy='186.5'
        r='27.5'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='343.5'
        cy='29'
        rx='28'
        ry='27.5'
      ></ellipse>
      <circle
        className='blob filled'
        fillorder='first'
        stroke='#3B5448'
        fill='#3B5448'
        cx='501'
        cy='817'
        r='28'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='501'
        cy='660'
        rx='27'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='501'
        cy='502'
        rx='27'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='501'
        cy='344.5'
        rx='27.5'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        strokeWidth='2'
        cx='501'
        cy='186.5'
        rx='27.5'
        ry='28'
      ></ellipse>
      <circle
        className='blob filled'
        fillorder='sixth'
        stroke='#3B5448'
        fill='#3B5448'
        cx='501'
        cy='29'
        r='28'
      ></circle>
    </g>
  </svg>
);
export const PatternLarge = () => (
  <svg
    width='1476px'
    height='530px'
    viewBox='0 0 1476 530'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    id='brand-pattern'
  >
    <g transform='translate(0.000000, 307.000000)'>
      <polyline
        id='route'
        stroke='#979797'
        points='28 342.5 186 28 500.5 185.5 658 28 973.5 500 1131 185.5 1289 342.5 1446 28'
      ></polyline>
      <circle
        className='blob'
        stroke='#3B5448'
        cx='1446'
        cy='500'
        r='28'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='1289'
        cy='500'
        rx='27'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='1131'
        cy='500'
        rx='27'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob filled'
        stroke='#3B5448'
        fill='#3B5448'
        cx='973.5'
        cy='500'
        rx='27.5'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='815.5'
        cy='500'
        rx='27.5'
        ry='28'
      ></ellipse>
      <circle
        className='blob'
        stroke='#3B5448'
        cx='658'
        cy='500'
        r='28'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='500.5'
        cy='500'
        rx='27.5'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='342.5'
        cy='500'
        rx='27.5'
        ry='28'
      ></ellipse>
      <circle
        className='blob'
        stroke='#3B5448'
        cx='186'
        cy='500'
        r='28'
      ></circle>
      <circle
        className='blob'
        stroke='#3B5448'
        cx='28'
        cy='500'
        r='28'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='1446'
        cy='342.5'
        rx='28'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob filled'
        stroke='#3B5448'
        fill='#3B5448'
        cx='1289'
        cy='342.5'
        rx='27'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='1131'
        cy='342.5'
        rx='27'
        ry='27.5'
      ></ellipse>
      <circle
        className='blob'
        stroke='#3B5448'
        cx='973.5'
        cy='342.5'
        r='27.5'
      ></circle>
      <circle
        className='blob'
        stroke='#3B5448'
        cx='815.5'
        cy='342.5'
        r='27.5'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='658'
        cy='342.5'
        rx='28'
        ry='27.5'
      ></ellipse>
      <circle
        className='blob'
        stroke='#3B5448'
        cx='500.5'
        cy='342.5'
        r='27.5'
      ></circle>
      <circle
        className='blob'
        stroke='#3B5448'
        cx='342.5'
        cy='342.5'
        r='27.5'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='186'
        cy='342.5'
        rx='28'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob filled'
        stroke='#3B5448'
        fill='#3B5448'
        cx='28'
        cy='342.5'
        rx='28'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='1446'
        cy='185.5'
        rx='28'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='1289'
        cy='185.5'
        rx='27'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob filled'
        stroke='#EF7856'
        fill='#EF7856'
        cx='1131'
        cy='185.5'
        rx='27'
        ry='27.5'
      ></ellipse>
      <circle
        className='blob'
        stroke='#3B5448'
        cx='973.5'
        cy='185.5'
        r='27.5'
      ></circle>
      <circle
        className='blob'
        stroke='#3B5448'
        cx='815.5'
        cy='185.5'
        r='27.5'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='658'
        cy='185.5'
        rx='28'
        ry='27.5'
      ></ellipse>
      <circle
        className='blob filled'
        stroke='#3B5448'
        fill='#3B5448'
        cx='500.5'
        cy='185.5'
        r='27.5'
      ></circle>
      <circle
        className='blob'
        stroke='#3B5448'
        cx='342.5'
        cy='185.5'
        r='27.5'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='186'
        cy='185.5'
        rx='28'
        ry='27.5'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='28'
        cy='185.5'
        rx='28'
        ry='27.5'
      ></ellipse>
      <circle
        className='blob filled'
        stroke='#3B5448'
        fill='#3B5448'
        cx='1446'
        cy='28'
        r='28'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='1289'
        cy='28'
        rx='27'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='1131'
        cy='28'
        rx='27'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='973.5'
        cy='28'
        rx='27.5'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='815.5'
        cy='28'
        rx='27.5'
        ry='28'
      ></ellipse>
      <circle
        className='blob filled'
        stroke='#3B5448'
        fill='#3B5448'
        cx='658'
        cy='28'
        r='28'
      ></circle>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='500.5'
        cy='28'
        rx='27.5'
        ry='28'
      ></ellipse>
      <ellipse
        className='blob'
        stroke='#3B5448'
        cx='342.5'
        cy='28'
        rx='27.5'
        ry='28'
      ></ellipse>
      <circle
        className='blob filled'
        stroke='#3B5448'
        fill='#3B5448'
        cx='186'
        cy='28'
        r='28'
      ></circle>
      <circle className='blob' stroke='#3B5448' cx='28' cy='28' r='28'></circle>
    </g>
  </svg>
);
export const PatternSmall = ({ orange }) => (
  <svg
    id='brand-pattern'
    className='small'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 952 354'
  >
    <g fill='none' fillRule='evenodd'>
      <g className='blob-row' transform='matrix(-1 0 0 1 951 1.490196)'>
        <ellipse className='blob' cx='26' cy='26.5' rx='26' ry='26.5' />
        <circle className='blob filled green' cx='175.5' cy='26.5' r='26.5' />
        <ellipse className='blob' cx='325' cy='26.5' rx='26' ry='26.5' />
        <ellipse className='blob' cx='475' cy='26.5' rx='26' ry='26.5' />
        <ellipse className='blob' cx='624.5' cy='26.5' rx='25.5' ry='26.5' />
        <ellipse className='blob' cx='774.5' cy='26.5' rx='25.5' ry='26.5' />
        <circle
          className={`blob filled ${orange === '3' ? 'orange' : 'green'}`}
          cx='923.5'
          cy='26.5'
          r='26.5'
        />
      </g>
      <g className='blob-row' transform='matrix(-1 0 0 1 951 151.490196)'>
        <circle
          className={`blob filled ${orange === '2' ? 'orange' : 'green'}`}
          cx='26'
          cy='26'
          r='26'
        />
        <ellipse className='blob' cx='175.5' cy='26' rx='26.5' ry='26' />
        <circle className='blob' cx='325' cy='26' r='26' />
        <circle className='blob' cx='475' cy='26' r='26' />
        <ellipse
          className={`blob filled ${orange === '1' ? 'orange' : 'green'}`}
          cx='624.5'
          cy='26'
          rx='25.5'
          ry='26'
        />
        <ellipse className='blob' cx='774.5' cy='26' rx='25.5' ry='26' />
        <ellipse className='blob' cx='923.5' cy='26' rx='26.5' ry='26' />
      </g>
      <g className='blob-row' transform='matrix(-1 0 0 1 951 301.490196)'>
        <circle className='blob' cx='26' cy='26' r='26' />
        <ellipse className='blob' cx='175.5' cy='26' rx='26.5' ry='26' />
        <circle className='blob' cx='325' cy='26' r='26' />
        <circle className='blob' cx='475' cy='26' r='26' />
        <ellipse className='blob' cx='624.5' cy='26' rx='25.5' ry='26' />
        <ellipse
          className='blob filled green'
          cx='774.5'
          cy='26'
          rx='25.5'
          ry='26'
        />
        <ellipse className='blob' cx='923.5' cy='26' rx='26.5' ry='26' />
      </g>
    </g>
  </svg>
);

PatternSmall.defaultProps = {
  orange: '1',
};

PatternSmall.propTypes = {
  orange: Number,
};
